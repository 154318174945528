import React, { useEffect, useState } from "react";
import Header from "./Header";
import "./BlogPost.scss";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";

export default function BlogPost() {
  const { id } = useParams();

  const [post, setPost] = useState({} as any);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const INDEX_JSON_URL = "https://duwc8vywliv4u.cloudfront.net/index.json";

  function ensureHttps(url: string) {
    if (!url) return url;
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  }

  useEffect(() => {
    // 1) Detect the user’s locale
    const userLocales = navigator.languages || [navigator.language];

    // 2) Fetch the index.json (list of posts)
    fetch(INDEX_JSON_URL)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Failed to fetch index.json (status: ${res.status})`);
        }
        return res.json();
      })
      .then(async (data) => {
        // data should be something like: { posts: [ { id, imageUrl, translations: { en: url, fr: url } }, ... ] }
        if (!data.posts) {
          throw new Error('index.json has no "posts" field');
        }

        // 3) For each post, fetch the translation JSON for the user’s locale (or fallback to 'en')
        data.posts.forEach(async (post: any) => {
          if (+post.id !== Number(id)) return;
          // Determine which translation URL to fetch
          const translationUrl =
            post.translations[userLocales[0]] || // best match
            post.translations["en"] || // fallback
            null;

          if (!translationUrl) {
            // If there's truly no translation for the user’s locale nor 'en',
            // we’ll still return basic info and skip fetching a translation
            return {
              ...post,
              title: "(No translation available)",
              body: "",
            };
          }

          // Fetch the translation JSON
          const response = await fetch(ensureHttps(translationUrl));
          if (!response.ok) {
            // If we fail to fetch translation, handle gracefully
            console.warn(
              `Failed to fetch translation for post ${post.id} at ${translationUrl}`
            );
            return {
              ...post,
              title: "(Error loading translation)",
              body: "",
            };
          }
          const translationData = await response.json();

          // Merge translation data (title, body) with the post’s metadata
          setPost({
            id: post.id,
            imageUrl: translationData.imageUrl || post.imageUrl,
            createdAt: translationData.createdAt || post.createdAt,
            updatedAt: translationData.updatedAt || post.updatedAt,
            title: translationData.title,
            body: translationData.body,
          });
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(err.message);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div>Loading blog posts...</div>;
  }

  if (error) {
    console.log(error);
    return <div style={{ color: "red" }}>Error: {error}</div>;
  }

  if (!post) {
    return <div>No post found.</div>;
  }

  return (
    <>
      <Header />
      <section className="faq section">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-md-12 col-12">
              <div className="single-inner">
                <div className="post-details">
                  <div className="main-content-head">
                    <div className="meta-information">
                      <h2 className="post-title">
                        {post.title || "Untitled Post"}
                      </h2>
                    </div>
                    <div className="post-thumbnails">
                      <img src={post.imageUrl} alt="#" />
                    </div>
                    <div className="detail-inner">
                      <ReactMarkdown className="line-break">
                        {post.body}
                      </ReactMarkdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
