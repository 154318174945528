import { BooleanInput, Edit, SimpleForm, TextInput } from "react-admin";

export const UserEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="displayName" />
      <BooleanInput source="chatEnabled" title="Chat Enabled" />
    </SimpleForm>
  </Edit>
);
